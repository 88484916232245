import useDismissNotifications from "@/apps/util/hooks/useDismissNotifications"
import { QuizSubmissionsDrawerContentProps } from "@/content-usage/drawer/ContentUsageQuizSubmissionsDrawerContent"
import QuizSubmissionsDetailSubmissionsListSidebar from "@/content-usage/drawer/quizzes/review-submissions/QuizSubmissionsDetailSubmissionsListSidebar"
import { QuizSubmissionsDetailContentQuery } from "@/content-usage/drawer/quizzes/review-submissions/__generated__/QuizSubmissionsDetailContentQuery.graphql"
import QuizSubmissionResultAnswerList from "@/content-usage/drawer/quizzes/submission-result/QuizSubmissionResultAnswerList"
import QuizSubmissionResultSidebar, {
  QuizSubmissionResultSidebarSkeleton,
} from "@/content-usage/drawer/quizzes/submission-result/QuizSubmissionResultSidebar"
import { QuizSubmissionUtils } from "@/content-usage/drawer/quizzes/utils/quizSubmissionUtils"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { useDrawerContext } from "@/core/context/DrawerContext"
import FolderIcon from "@/core/ui/images/empty-state-illustrations/folder.svg"
import Relay from "@/relay/relayUtils"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import DeleteWebFormSubmissionButton from "@/web-form/submission/DeleteWebFormSubmissionButton"
import { WebFormSubmissionsUtils } from "@/web-form/utils/webFormSubmissionsUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoButton,
  DiscoDivider,
  DiscoEmptyState,
  DiscoIcon,
  DiscoIconButton,
  DiscoText,
} from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import { useEffect, useRef } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

const WEB_FORM_SUBMISSIONS_PER_PAGE = 10

interface QuizSubmissionsDetailContentProps
  extends TestIDProps,
    QuizSubmissionsDrawerContentProps {
  contentLabel: string
  showResult: boolean
  membersOnly?: boolean
}

function QuizSubmissionsDetailContent({
  testid = "QuizSubmissionsDetailContent",
  contentLabel,
  contentUsageId,
  revision: { id: revisionId },
  showResult,
  membersOnly,
}: QuizSubmissionsDetailContentProps) {
  const isMobile = useIsMobile()
  const drawer = useContentUsageDrawer()
  const { openDrawerSidebar } = useDrawerContext()
  const showSpecificSubmissionOnInitialLoad = useRef(true)

  const {
    toolbarState,
    handleSearchChange,
    handleOrderBy,
    handleFilterBy,
    handleRemoveFilter,
    handleRemoveSubmissionIds,
    handleAddSubmissionIds,
  } = WebFormSubmissionsUtils.useToolbarState({
    ...WebFormSubmissionsUtils.INITIAL_TOOLBAR_STATE,
    submissionIds:
      showSpecificSubmissionOnInitialLoad.current && drawer.params.submissionId
        ? [drawer.params.submissionId]
        : null,
  })

  const { node } = useLazyLoadQuery<QuizSubmissionsDetailContentQuery>(
    graphql`
      query QuizSubmissionsDetailContentQuery($id: ID!) {
        node(id: $id) {
          ... on ContentUsage {
            id
            __typename
            ...usePermissionsFragment
          }
        }
      }
    `,
    { id: contentUsageId }
  )

  const {
    submissions,
    revision,
    pagination: { data, hasNext, isLoadingNext, loadNext },
  } = WebFormSubmissionsUtils.usePaginatedSubmissions(
    {
      include: true,
      first: WEB_FORM_SUBMISSIONS_PER_PAGE,
      usageId: contentUsageId,
      revisionId,
      membersOnly,
    },
    toolbarState
  )

  useEffect(() => {
    // prevent setting toolbar submissionIds if param wasn't provided on intialLoad
    showSpecificSubmissionOnInitialLoad.current = false
    // set query param for first submission if none exists
    if (!drawer.params.submissionId && !showSpecificSubmissionOnInitialLoad.current)
      drawer.setParams({ ...drawer.params, submissionId: submissions[0]?.id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submissionIndex = submissions.findIndex(
    (s) => s.id === drawer.params.submissionId
  )

  const submission = submissionIndex === -1 ? undefined : submissions[submissionIndex]

  const { filteredAnswers, filter, setFilter, currentQuestionId, setCurrentQuestionId } =
    QuizSubmissionUtils.useAnswersListState({
      revision,
      submission,
      mode: "reviewer",
      answerKeyMap: new Map(
        Relay.connectionToArray(revision?.questions).map((q) => [
          q.id,
          q.answerKey?.correctIds || [],
        ])
      ),
    })

  const classes = useStyles()
  useDismissNotifications({ entityId: submission?.id }, { skip: !submission })

  useEffect(() => {
    // for mobile, we need to load next page if we are on the last submission and there is a next page
    if (isMobile && hasNext && submissionIndex === submissions.length - 1)
      loadNext(WEB_FORM_SUBMISSIONS_PER_PAGE)
  }, [hasNext, loadNext, submissionIndex, submissions.length, isMobile])

  const contentUsage = Relay.narrowNodeType(node, "ContentUsage")
  if (!contentUsage) return null

  return (
    <div data-testid={`${testid}.container`} className={classes.container}>
      {isMobile && (
        <div className={classes.mobileHeader}>
          {Boolean(submissions.length) && (
            <div className={classes.submissionsHeaderSection}>
              <DiscoIconButton
                size={"small"}
                onClick={handleSubmissionPrev}
                disabled={!submissions[submissionIndex - 1]}
              >
                {<DiscoIcon icon={"chevron"} className={classes.leftChevron} />}
              </DiscoIconButton>
              <DiscoText variant={"body-md-600"}>
                {`Submission ${(submissionIndex ?? 0) + 1}/${
                  data?.submissions?.totalCount ?? 0
                }`}
              </DiscoText>
              <DiscoIconButton
                size={"small"}
                onClick={handleSubmissionNext}
                disabled={!submissions[submissionIndex + 1] && !hasNext}
              >
                {<DiscoIcon icon={"chevron"} className={classes.rightChevron} />}
              </DiscoIconButton>
            </div>
          )}

          <DiscoDivider style={{ margin: 0 }} />
          {renderSubmissionsListSidebar()}

          {submission && (
            <>
              <DiscoDivider style={{ margin: 0 }} />
              <div className={classes.userDetails}>
                <div className={classes.user}>
                  <ProfileAvatar userKey={submission.user} size={24} />
                  <DiscoText truncateText={1}>{`${submission.user.fullName}`}</DiscoText>
                </div>
                {showResult && (
                  <DiscoButton
                    color={"transparent"}
                    onClick={openDrawerSidebar}
                    rightIcon={
                      <DiscoIcon icon={"chevron"} className={classes.rightChevron} />
                    }
                    className={classes.resultsHeaderButton}
                  >
                    {"Result Summary"}
                  </DiscoButton>
                )}
              </div>

              {/* portaled to disco drawer mobile sidebar */}
              {renderResultsSidebar()}
            </>
          )}
        </div>
      )}
      {!isMobile && renderSubmissionsListSidebar()}
      {submission ? (
        <>
          <QuizSubmissionResultAnswerList
            revision={revision}
            submission={submission}
            answers={filteredAnswers}
            currentQuestionId={currentQuestionId}
            handleRemoveQuestionId={() => setCurrentQuestionId(null)}
            mode={"reviewer"}
          />
          {!isMobile && renderResultsSidebar()}
        </>
      ) : (
        <DiscoEmptyState
          testid={`${testid}.empty-state`}
          icon={<FolderIcon width={"157px"} height={"151px"} />}
          title={"It's empty here!"}
          subtitle={
            submissions.length
              ? "Select a submission to view"
              : "There are no submissions"
          }
        />
      )}
    </div>
  )

  function renderSubmissionsListSidebar() {
    return (
      <QuizSubmissionsDetailSubmissionsListSidebar
        width={isMobile ? "100%" : undefined}
        submissions={submissions}
        revision={revision}
        toolbarState={toolbarState}
        toolbarHandlers={{
          handleSearchChange,
          handleOrderBy,
          handleFilterBy,
          handleRemoveFilter,
          handleRemoveSubmissionIds,
        }}
        hasNext={hasNext}
        scrolledIntoViewProps={{
          isLoading: isLoadingNext,
          onScrolledIntoView: () => loadNext(WEB_FORM_SUBMISSIONS_PER_PAGE),
        }}
        showResult={showResult}
      />
    )
  }

  function renderResultsSidebar() {
    if (!submission || !showResult) return null
    return (
      <QuizSubmissionResultSidebar
        result={submission.result}
        contentUsageId={contentUsageId}
        revisionId={revisionId}
        submission={submission}
        answers={filteredAnswers}
        contentLabel={contentLabel}
        filter={filter}
        setFilter={setFilter}
        currentQuestionId={currentQuestionId}
        goToQuestion={(id) => setCurrentQuestionId(id)}
        user={submission.user}
        toolbarState={toolbarState}
        toolbarHandlers={{
          handleAddSubmissionIds,
        }}
        moreOptions={
          <DiscoMoreActionsDropdown
            key={`${testid}-more-actions`}
            testid={`${testid}.more-actions`}
            rotateIcon
            width={40}
            height={40}
          >
            <DeleteWebFormSubmissionButton
              submissionId={submission.id}
              contentUsageKey={contentUsage}
            >
              {(props) => (
                <DiscoDropdownItem
                  title={"Delete"}
                  testid={`${testid}.actions-delete`}
                  {...props}
                />
              )}
            </DeleteWebFormSubmissionButton>
          </DiscoMoreActionsDropdown>
        }
      />
    )
  }

  function handleSubmissionNext() {
    const nextSubmission = submissions[submissionIndex + 1]
    if (nextSubmission)
      drawer.setParams({ ...drawer.params, submissionId: nextSubmission.id })
  }
  function handleSubmissionPrev() {
    const prevSubmission = submissions[submissionIndex - 1]
    if (prevSubmission)
      drawer.setParams({ ...drawer.params, submissionId: prevSubmission.id })
  }
}

function QuizSubmissionsDetailContentSkeleton() {
  const classes = useStyles()
  const isMobile = useIsMobile()
  return (
    <div className={classes.container}>
      {!isMobile && <QuizSubmissionsDetailSubmissionsListSidebar.Skeleton />}
      <QuizSubmissionResultAnswerList.Skeleton />
      {!isMobile && <QuizSubmissionResultSidebarSkeleton />}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    overflow: "hidden", // prevent scroll from showing up on drawer
    height: "100%",
    width: "100%",
    gridTemplateColumns: `auto 1fr auto`,
    backgroundColor: theme.palette.background.default,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridAutoRows: "min-content",
    },
  },
  mobileHeader: {
    position: "sticky",
    top: 0,
    left: 0,
    width: "100%",
    minWidth: 0,
    overflow: "hidden",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  rightChevron: {
    transform: "rotate(90deg)",
  },
  leftChevron: {
    transform: "rotate(-90deg)",
  },
  resultsHeaderButton: {
    padding: 0,
  },
  submissionsHeaderSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(1, 2),
    gap: theme.spacing(1),
  },
  userDetails: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(1, 2),
    gap: theme.spacing(1),
  },
  user: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(1),
    minWidth: 0,
  },
}))

export default Relay.withSkeleton({
  component: QuizSubmissionsDetailContent,
  skeleton: QuizSubmissionsDetailContentSkeleton,
})
