/**
 * @generated SignedSource<<baf0a12d9efb21483678ead2744fdf0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductRole = "instructor" | "manager" | "member" | "%future added value";
export type QuizSubmissionsOverviewContentQuery$variables = {
  id: string;
  roles?: ReadonlyArray<ProductRole> | null;
};
export type QuizSubmissionsOverviewContentQuery$data = {
  readonly contentUsage: {
    readonly " $fragmentSpreads": FragmentRefs<"QuizSubmissionsOverviewContentHeader_ContentUsageFragment" | "QuizSubmissionsTable_ContentUsageFragment">;
  } | null;
};
export type QuizSubmissionsOverviewContentQuery = {
  response: QuizSubmissionsOverviewContentQuery$data;
  variables: QuizSubmissionsOverviewContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roles"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "roles",
    "variableName": "roles"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuizSubmissionsOverviewContentQuery",
    "selections": [
      {
        "alias": "contentUsage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v2/*: any*/),
                "kind": "FragmentSpread",
                "name": "QuizSubmissionsOverviewContentHeader_ContentUsageFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "QuizSubmissionsTable_ContentUsageFragment"
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuizSubmissionsOverviewContentQuery",
    "selections": [
      {
        "alias": "contentUsage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "passPercentage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "ProductMembershipNodeConnection",
                    "kind": "LinkedField",
                    "name": "productMemberships",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminCanLearnMode",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0eed931dd76c35a328a6870d399babcc",
    "id": null,
    "metadata": {},
    "name": "QuizSubmissionsOverviewContentQuery",
    "operationKind": "query",
    "text": "query QuizSubmissionsOverviewContentQuery(\n  $id: ID!\n  $roles: [ProductRole!]\n) {\n  contentUsage: node(id: $id) {\n    __typename\n    ... on ContentUsage {\n      ...QuizSubmissionsOverviewContentHeader_ContentUsageFragment_2tksZD\n      ...QuizSubmissionsTable_ContentUsageFragment\n    }\n    id\n  }\n}\n\nfragment QuizSubmissionsOverviewContentHeader_ContentUsageFragment_2tksZD on ContentUsage {\n  passPercentage\n  product {\n    productMemberships(roles: $roles) {\n      totalCount\n    }\n    adminCanLearnMode\n    id\n  }\n}\n\nfragment QuizSubmissionsTable_ContentUsageFragment on ContentUsage {\n  passPercentage\n  product {\n    adminCanLearnMode\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "03a4d204f998547595a335ac86f506d5";

export default node;
