/**
 * @generated SignedSource<<6dd774bd6471502d4704dfd560119c0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "collection" | "content" | "content_usage_confirmation" | "curriculum" | "dashboard_block_content" | "member_onboarding" | "organization" | "product_app" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InlineContentDrawerTemplate_ModuleContentUsageFragment$data = {
  readonly content: {
    readonly children: {
      readonly totalCount: number;
    } | null;
    readonly id: string;
    readonly isCurriculumModule: boolean;
    readonly name: string | null;
  };
  readonly entity: ContentUsageEntity;
  readonly entityId: string;
  readonly hasPrerequisites: boolean;
  readonly id: string;
  readonly isLocked: boolean;
  readonly isReleased: boolean;
  readonly releasedAt: string | null;
  readonly " $fragmentType": "InlineContentDrawerTemplate_ModuleContentUsageFragment";
};
export type InlineContentDrawerTemplate_ModuleContentUsageFragment$key = {
  readonly " $data"?: InlineContentDrawerTemplate_ModuleContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InlineContentDrawerTemplate_ModuleContentUsageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InlineContentDrawerTemplate_ModuleContentUsageFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releasedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isReleased",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPrerequisites",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCurriculumModule",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "children",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "a49d36cb9bc48bb0c2b1db5cc2fb87a1";

export default node;
