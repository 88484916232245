/**
 * @generated SignedSource<<6ab7f6c3e2d2d7ddd994c8f592619f07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type QuizSubmissionsOverviewContentHeaderQuery$variables = {
  contentUsageId: string;
  includePercentage: boolean;
  membersOnly?: boolean | null;
  webFormId: string;
};
export type QuizSubmissionsOverviewContentHeaderQuery$data = {
  readonly webForm: {
    readonly submissionsAverageDurationSeconds?: number;
    readonly submissionsAveragePercentage?: number;
  } | null;
};
export type QuizSubmissionsOverviewContentHeaderQuery = {
  response: QuizSubmissionsOverviewContentHeaderQuery$data;
  variables: QuizSubmissionsOverviewContentHeaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentUsageId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includePercentage"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "membersOnly"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "webFormId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "webFormId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "contentUsageId",
    "variableName": "contentUsageId"
  },
  {
    "kind": "Variable",
    "name": "membersOnly",
    "variableName": "membersOnly"
  }
],
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": (v5/*: any*/),
      "kind": "ScalarField",
      "name": "submissionsAverageDurationSeconds",
      "storageKey": null
    },
    {
      "condition": "includePercentage",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": (v5/*: any*/),
          "kind": "ScalarField",
          "name": "submissionsAveragePercentage",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "WebForm",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QuizSubmissionsOverviewContentHeaderQuery",
    "selections": [
      {
        "alias": "webForm",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "QuizSubmissionsOverviewContentHeaderQuery",
    "selections": [
      {
        "alias": "webForm",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "804ec04c40ccff468289112f9418afd7",
    "id": null,
    "metadata": {},
    "name": "QuizSubmissionsOverviewContentHeaderQuery",
    "operationKind": "query",
    "text": "query QuizSubmissionsOverviewContentHeaderQuery(\n  $webFormId: ID!\n  $contentUsageId: ID!\n  $includePercentage: Boolean!\n  $membersOnly: Boolean\n) {\n  webForm: node(id: $webFormId) {\n    __typename\n    ... on WebForm {\n      submissionsAverageDurationSeconds(contentUsageId: $contentUsageId, membersOnly: $membersOnly)\n      submissionsAveragePercentage(contentUsageId: $contentUsageId, membersOnly: $membersOnly) @include(if: $includePercentage)\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0de494d1b836fef5d615526e1cf13dc";

export default node;
