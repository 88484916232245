/**
 * @generated SignedSource<<0c6410ce905cd8a01b8b7226feece04a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeleteContentLabelInput = {
  contentLabelId: string;
};
export type DeleteContentLabelButtonMutation$variables = {
  input: DeleteContentLabelInput;
};
export type DeleteContentLabelButtonMutation$data = {
  readonly response: {
    readonly deletedContentLabelId: string | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type DeleteContentLabelButtonMutation = {
  response: DeleteContentLabelButtonMutation$data;
  variables: DeleteContentLabelButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedContentLabelId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteContentLabelButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "DeleteContentLabelResponse",
        "kind": "LinkedField",
        "name": "deleteContentLabel",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteContentLabelButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "DeleteContentLabelResponse",
        "kind": "LinkedField",
        "name": "deleteContentLabel",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedContentLabelId"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7344038148e27299d1f550b7c035d0dc",
    "id": null,
    "metadata": {},
    "name": "DeleteContentLabelButtonMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteContentLabelButtonMutation(\n  $input: DeleteContentLabelInput!\n) {\n  response: deleteContentLabel(input: $input) {\n    deletedContentLabelId\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9c1efbf537d58f581f1011c5b2356940";

export default node;
