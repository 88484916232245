import ContentUsageMarkAsCompleteButton from "@/content-usage/buttons/ContentUsageMarkAsCompleteButton"
import { useContentUsageDrawerNavigation } from "@/content-usage/drawer/footer/useContentUsageDrawerNavigation"
import { CurriculumDrawerFooterContentFragment$key } from "@/content-usage/drawer/footer/__generated__/CurriculumDrawerFooterContentFragment.graphql"
import ContentUtils from "@/content/util/contentUtils"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import { DiscoButtonSkeleton, DiscoIcon, DiscoIconButton } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface CurriculumDrawerFooterContentProps extends TestIDProps {
  contentUsageKey?: CurriculumDrawerFooterContentFragment$key | null
}

function CurriculumDrawerFooterContent({
  contentUsageKey,
  testid = "CurriculumDrawerFooterContent",
}: CurriculumDrawerFooterContentProps) {
  const contentUsage = useFragment<CurriculumDrawerFooterContentFragment$key>(
    graphql`
      fragment CurriculumDrawerFooterContentFragment on ContentUsage {
        id
        viewerHasCompleted
        content {
          type
          systemTaskKind
        }
        isLocked
        submissionMethod
        ...usePermissionsFragment
        ...ContentUsageMarkAsCompleteButtonFragment
      }
    `,
    contentUsageKey || null
  )
  const permissions = usePermissions(contentUsage)
  const activeProduct = useActiveProduct()
  const isCurriculumItemActionRendered = contentUsage
    ? !(
        permissions.has("content.manage") ||
        contentUsage.isLocked ||
        // Web form content are completed when the WebFormSubmission is completed
        ContentUtils.isWebFormContent(contentUsage.content.type) ||
        // Connect slack tasks are completed when the user connects their slack account
        contentUsage.content.systemTaskKind === "connect_slack" ||
        // Default to next button if user has completed content
        contentUsage.viewerHasCompleted ||
        // Assignment with submissions has inline CTA to submit, next button in footer
        contentUsage.submissionMethod === "submission"
      ) && activeProduct // Only render complete button if the drawer is rendered in the product route
    : false

  const theme = useTheme()
  const classes = useStyles()

  const { nextUsage, prevUsage, handleNav } = useContentUsageDrawerNavigation()

  return (
    <>
      <DiscoIconButton
        className={classes.navIcon}
        onClick={() => handleNav(prevUsage)}
        color={theme.palette.text.primary}
        disabled={!prevUsage}
        testid={`${testid}.previous`}
      >
        <DiscoIcon icon={"arrow-stem-left"} />
      </DiscoIconButton>
      {isCurriculumItemActionRendered ? (
        contentUsage && (
          <ContentUsageMarkAsCompleteButton
            contentUsageKey={contentUsage}
            testid={testid}
          />
        )
      ) : (
        <>
          <DiscoIconButton
            className={classes.navIcon}
            onClick={() => handleNav(nextUsage)}
            color={theme.palette.text.primary}
            disabled={!nextUsage}
            testid={`${testid}.next`}
          >
            <DiscoIcon icon={"arrow-stem-right"} />
          </DiscoIconButton>
        </>
      )}
    </>
  )
}

function CurriculumDrawerFooterContentSkeleton() {
  return (
    <>
      <DiscoButtonSkeleton width={"48px"} height={"48px"} />
      <DiscoButtonSkeleton width={"48px"} height={"48px"} />
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  navIcon: {
    border: `2px solid ${theme.palette.constants.stroke}`,
    borderRadius: theme.measure.borderRadius.big,
    width: theme.spacing(5),
    height: theme.spacing(5),
    padding: theme.spacing(1),
  },
}))

export default Relay.withSkeleton({
  component: CurriculumDrawerFooterContent,
  skeleton: CurriculumDrawerFooterContentSkeleton,
})
