/**
 * @generated SignedSource<<e0c2378c0eabe7630d3ed34fa05fddc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuizSubmissionsTableRow_submission$data = {
  readonly completedAt: string | null;
  readonly durationSeconds: number | null;
  readonly hasPassed: boolean | null;
  readonly id: string;
  readonly result: {
    readonly correctAnswers: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly incorrectAnswers: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly percentage: number;
    readonly score: number;
    readonly total: number;
  } | null;
  readonly user: {
    readonly fullName: string;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment">;
  };
  readonly " $fragmentType": "QuizSubmissionsTableRow_submission";
};
export type QuizSubmissionsTableRow_submission$key = {
  readonly " $data"?: QuizSubmissionsTableRow_submission$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizSubmissionsTableRow_submission">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizSubmissionsTableRow_submission",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "durationSeconds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPassed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebFormSubmissionResult",
      "kind": "LinkedField",
      "name": "result",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "score",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormAnswer",
          "kind": "LinkedField",
          "name": "correctAnswers",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormAnswer",
          "kind": "LinkedField",
          "name": "incorrectAnswers",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileAvatarFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WebFormSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "2a17484c3304df1b1985d549896fb4bf";

export default node;
