/**
 * @generated SignedSource<<a65031bd0bab7dc0d771ed3bf1894bcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTrackContentViewFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "useTrackContentViewFragment";
};
export type useTrackContentViewFragment$key = {
  readonly " $data"?: useTrackContentViewFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTrackContentViewFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useTrackContentViewFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "fe311117a4cc2bc8ddf803341e2dcfb8";

export default node;
